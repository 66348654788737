import * as React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import PageIntroSection from '../../components/PageIntroSection/PageIntroSection';
import MilitaryHero from '../../components/MilitaryHero/MilitaryHero';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import TestimonialVideos from '../../components/TestimonialVideos/TestimonialVideos';
import Cards from '../../components/Cards/Cards';
import StandBehindSection from '../../components/StandBehindSection/StandBehindSection';

// @ts-ignore
import slide1 from '../../images/military-slide-1.jpg';
// @ts-ignore
import slide2 from '../../images/military-slide-2.png';
// @ts-ignore
import slide3 from '../../images/military-slide-3.png';

// @ts-ignore
import card1 from '../../images/serve-card-1.svg';
// @ts-ignore
import card2 from '../../images/serve-card-2.svg';
// @ts-ignore
import card3 from '../../images/serve-card-3.svg';
// @ts-ignore
import card4 from '../../images/serve-card-4.svg';
import HonoredToServeSection from '../../components/HonoredToServeSection/HonoredToServeSection';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import { graphql, useStaticQuery } from 'gatsby';
import BestPeopleSection from '../../components/BestPeopleSection/BestPeopleSection';

const MilitaryPage: React.FC = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	const testimonials = [
		{
			videoURL: 'https://www.youtube.com/embed/5xE3O4KRXus',
			name: 'Lane',
			position: 'Assistant Manager',
			buttonText: 'Search Management Jobs',
			buttonLink: '/search/?term=Retail Management'
		},
		{
			videoURL: 'https://www.youtube.com/embed/6bE0AKxC8W0',
			name: 'Wayne',
			position: 'Assistant Manager',
			buttonText: 'Search Management Jobs',
			buttonLink: '/search/?term=Retail Management'
		},
		{
			videoURL: 'https://www.youtube.com/embed/2iIvHY0VSrs',
			name: 'Matt',
			position: 'Auto Technician',
			buttonText: 'Search Technician Jobs',
			buttonLink: '/search/?term=Retail Automotive Technician'
		}
	];
	const cards = [
		{
			imgSrc: card1,
			imgWidth: 88,
			h3: 'Flexibility to Serve',
			p: 'For those still actively serving, we provide you and your spouse with the flexibility needed to serve in the National Guard or Reserves.'
		},
		{
			imgSrc: card2,
			imgWidth: 100,
			h3: 'Spouse Employment',
			p: 'We know military spouses aspire in a great career too; which is why we are proud to help employ military spouses who seek employment.'
		},
		{
			imgSrc: card3,
			imgWidth: 85,
			h3: 'Training',
			p: 'We support reserve training commitments.'
		},
		{
			imgSrc: card4,
			imgWidth: 59,
			h3: 'Job Protection',
			p: 'We provide job protection for both service members and eligible family members during an active leave or medical leave.'
		}
	];
	const carouselImages = [
		{
			imgSrc: slide1,
			topText: 'Lane - Assistant Manager (United States Marine Core)'
		},
		{
			imgSrc: slide2,
			topText: 'Wayne - Assistant Manager (United States Army)'
		},
		{
			imgSrc: slide3,
			topText: 'Matt -  Auto Technician (Army National Guard)'
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<MilitaryHero />
			<PageIntroSection
				h2={'WHO WE ARE'}
				pTags={[
					{
						text: "We recognize the unique skill sets that veterans bring to the workforce. That is why we place an emphasis on hiring qualified veterans into our ranks. At Belle Tire, we are proud to empower and employ former military to begin a successful career. We're always looking for smart, driven and talented veterans to join our team."
					},
					{
						text: 'We strongly believe that veterans make us a better company!',
						bold: true
					}
				]}
			/>
			<ImageCarousel h2={'Meet Our Veterans'} images={carouselImages} />
			<TestimonialVideos testimonials={testimonials} />
			<Cards cards={cards} />
			<StandBehindSection />
			<JobAlerts />
			<HonoredToServeSection />
			<BestPeopleSection />
			<JobSearchForm data={data} />
		</Layout>
	);
};

export default MilitaryPage;
