import * as React from 'react';

interface CardsProps {
	cards: Array<{
		imgSrc: string;
		imgWidth: number;
		h3: string;
		p: string;
	}>;
}

const Cards: React.FC<CardsProps> = (props: CardsProps) => {
	return (
		<section className={'bg-bt-light-gray px-3 py-20'}>
			<div className={'container mx-auto text-center'}>
				<h2 className={'text-gray pb-8 font-rubik text-4xl	'}>
					Proud to Serve You
				</h2>
				<div className={'flex auto-cols-auto gap-8 max-lg:flex-col'}>
					{props.cards.map((card) => {
						return (
							<div
								className={
									'flex flex-col items-center rounded-3xl bg-white p-8 text-center text-bt-gray shadow lg:w-1/4'
								}
							>
								<div
									className={
										'flex h-28 w-28 flex-col items-center justify-center py-4'
									}
								>
									<img
										width={card.imgWidth}
										src={card.imgSrc}
									/>
								</div>
								<h3 className={'pb-6 font-rubik text-2xl'}>
									{card.h3}
								</h3>
								<p className={'pb-8 font-rubik text-xl'}>
									{card.p}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Cards;
