import * as React from 'react';

// @ts-ignore
import veteransBadge from '../../images/veterans-badge.png';
const HonoredToServeSection: React.FC = () => {
	return (
		<section className={'bg-bt-light-gray px-3 py-20'}>
			<div className={'container mx-auto flex flex-col md:flex-row'}>
				<div
					className={
						'flex flex-col items-center justify-center md:w-2/5'
					}
				>
					<img
						src={veteransBadge}
						className={'w-[180px] max-md:pb-10 md:w-[211px]'}
						alt={'Veterans Badge'}
					/>
				</div>
				<div
					className={
						'flex flex-col text-bt-gray max-md:items-center max-md:text-center md:pl-20'
					}
				>
					<h2 className={'font-rubik text-4xl font-bold'}>
						HONORED TO SERVE YOU
					</h2>
					<p className={'pt-8 font-rubik text-lg'}>
						Belle Tire was recently recognized for our commitment to
						hiring veterans and active military service members by
						Michigan Veterans Affairs Agency.
					</p>
					<p className={'font-rubik text-lg'}>
						We do more because you deserve nothing less. And we look
						forward to continue growing our recognition as a
						preferred employer for veterans.
					</p>
				</div>
			</div>
		</section>
	);
};

export default HonoredToServeSection;
