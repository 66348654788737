import * as React from 'react';
// @ts-ignore
import standBehindBackgroundImage from '../../images/stand-behind-background.png';
const StandBehindSection: React.FC = () => {
	return (
		<section
			style={{ backgroundImage: `url('${standBehindBackgroundImage}')` }}
			className={'bg-cover bg-top px-3 py-8'}
		>
			<div
				className={
					'container mx-auto flex flex-col py-14 text-center text-white'
				}
			>
				<h2 className={'font-rubik text-4xl font-bold'}>
					We stand behind our commitment
				</h2>
				<p
					className={
						'mx-auto  py-8 font-rubik text-[26px] max-md:text-xl'
					}
				>
					We are honored to already employ Veterans and are looking to
					have even more join our team! Are you a veteran and
					interested in a new career? Apply today and let’s work
					together to apply your military skills to serving our
					customers.
				</p>
			</div>
		</section>
	);
};

export default StandBehindSection;
