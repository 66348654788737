import * as React from 'react';

// @ts-ignore
import bgImage from '../../images/military-hero.png';
// @ts-ignore
import badge from '../../images/military-badge.png';
const MilitaryHero: React.FC = () => {
	return (
		<section
			style={{ backgroundImage: `url('${bgImage}')` }}
			className={'h-[600px] bg-cover bg-top px-3 max-md:h-[450px]'}
		>
			<div
				className={
					'container mx-auto flex flex-row text-center max-md:justify-center'
				}
			>
				<img
					src={badge}
					width={300}
					alt={'Belle Tire military badge.'}
				/>
			</div>
		</section>
	);
};

export default MilitaryHero;
